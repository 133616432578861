//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'CoreBanner',
  mixins: [commonCoreStrings],
  data() {
    return {
      bannerClosed: false,
    };
  },
  methods: {
    toggleBanner() {
      this.bannerClosed = !this.bannerClosed;
    },
  },
  $trs: {
    openButton: 'More Info',
  },
};

