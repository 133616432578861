//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { PermissionTypes, UserKinds } from 'kolibri.coreVue.vuex.constants';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';

export default {
  name: 'PermissionsIcon',
  components: {
    UserTypeDisplay,
  },
  props: {
    permissionType: {
      type: String,
      required: true,
      validator(value) {
        return [PermissionTypes.SUPERUSER, PermissionTypes.LIMITED_PERMISSIONS].includes(value);
      },
    },
  },
  computed: {
    UserKinds() {
      return UserKinds;
    },
    hasSuperAdminPermission() {
      return this.permissionType === PermissionTypes.SUPERUSER;
    },
    hasLimitedPermissions() {
      return this.permissionType === PermissionTypes.LIMITED_PERMISSIONS;
    },
  },
  $trs: {
    limitedPermissionsTooltip: 'Limited permissions',
  },
};

