//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import find from 'lodash/find';
import UiAlert from 'kolibri-design-system/lib/keen/UiAlert';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import commonSyncElements from 'kolibri.coreVue.mixins.commonSyncElements';
import { deleteAddress, fetchStaticAddresses, fetchDynamicAddresses } from './api';

const Stages = Object.freeze({
  FETCHING_ADDRESSES: 'FETCHING_ADDRESSES',
  FETCHING_SUCCESSFUL: 'FETCHING_SUCCESSFUL',
  FETCHING_FAILED: 'FETCHING_FAILED',
  DELETING_ADDRESS: 'DELETING_ADDRESS',
  DELETING_SUCCESSFUL: 'DELETING_SUCCESSFUL',
  DELETING_FAILED: 'DELETING_FAILED',
  PEER_DISCOVERY_STARTED: 'PEER_DISCOVERY_STARTED',
  PEER_DISCOVERY_SUCCESSFUL: 'PEER_DISCOVERY_SUCCESSFUL',
  PEER_DISCOVERY_FAILED: 'PEER_DISCOVERY_FAILED',
});

export default {
  name: 'SelectAddressForm',
  components: {
    UiAlert,
  },
  mixins: [commonCoreStrings, commonSyncElements],
  props: {
    discoverySpinnerTime: { type: Number, default: 2500 },
    // Facility filter only needed on SyncFacilityModalGroup
    filterByFacilityId: {
      type: String,
      required: false,
    },
    // Channel filter only needed on ManageContentPage/SelectNetworkAddressModal
    filterByChannelId: {
      type: String,
      required: false,
    },
    // Hides "New address" button and other saved locations
    hideSavedAddresses: {
      type: Boolean,
      default: false,
    },
    // If an ID is provided, that address's radio button will be automatically selected
    selectedId: {
      type: String,
      required: false,
    },
    // Disables all the form controls
    formDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      savedAddresses: [],
      savedAddressesInitiallyFetched: false,
      discoveredAddresses: [],
      discoveredAddressesInitiallyFetched: true,
      selectedAddressId: '',
      showUiAlerts: false,
      stage: '',
      discoveryStage: '',
      Stages,
    };
  },
  computed: {
    fetchAddressArgs() {
      if (this.filterByChannelId) {
        return { channelId: this.filterByChannelId };
      } else if (this.filterByFacilityId) {
        return { facilityId: this.filterByFacilityId };
      } else {
        return {};
      }
    },
    addresses() {
      return this.savedAddresses.concat(this.discoveredAddresses);
    },
    initialFetchingComplete() {
      return this.savedAddressesInitiallyFetched && this.discoveredAddressesInitiallyFetched;
    },
    submitDisabled() {
      return (
        this.selectedAddressId === '' ||
        this.stage === this.Stages.FETCHING_ADDRESSES ||
        this.stage === this.Stages.DELETING_ADDRESS ||
        this.discoveryStage === this.Stages.PEER_DISCOVERY_FAILED
      );
    },
    newAddressButtonDisabled() {
      return this.hideSavedAddresses || this.stage === this.Stages.FETCHING_ADDRESSES;
    },
    requestsFailed() {
      return (
        this.stage === this.Stages.FETCHING_FAILED || this.stage === this.Stages.DELETING_FAILED
      );
    },
    discoveringPeers() {
      return this.discoveryStage === this.Stages.PEER_DISCOVERY_STARTED;
    },
    discoveryFailed() {
      return this.discoveryStage === this.Stages.PEER_DISCOVERY_FAILED;
    },
    uiAlertProps() {
      if (this.stage === this.Stages.FETCHING_FAILED) {
        return {
          text: this.$tr('fetchingFailedText'),
          type: 'error',
        };
      }
      if (this.discoveryStage === this.Stages.PEER_DISCOVERY_FAILED) {
        return {
          text: this.$tr('fetchingFailedText'),
          type: 'error',
        };
      }
      if (this.stage === this.Stages.DELETING_FAILED) {
        return {
          text: this.$tr('deletingFailedText'),
          type: 'error',
        };
      }
      return null;
    },
  },
  beforeMount() {
    this.startDiscoveryPolling();
    return this.refreshSavedAddressList();
  },
  mounted() {
    // Wait a little bit of time before showing UI alerts so there is no flash
    // if data comes back quickly
    setTimeout(() => {
      this.showUiAlerts = true;
    }, 100);
  },
  destroyed() {
    this.stopDiscoveryPolling();
  },
  methods: {
    refreshSavedAddressList() {
      this.stage = this.Stages.FETCHING_ADDRESSES;
      this.savedAddresses = [];
      return fetchStaticAddresses(this.fetchAddressArgs)
        .then(addresses => {
          this.savedAddresses = addresses;
          this.resetSelectedAddress();
          this.stage = this.Stages.FETCHING_SUCCESSFUL;
          this.savedAddressesInitiallyFetched = true;
          if (this.savedAddresses.find(({ id }) => this.selectedId === id)) {
            this.selectedAddressId = this.selectedId;
          }
        })
        .catch(() => {
          this.stage = this.Stages.FETCHING_FAILED;
        });
    },
    resetSelectedAddress() {
      const availableAddress = find(this.addresses, { available: true });
      if (availableAddress) {
        this.selectedAddressId = availableAddress.id;
      } else {
        this.selectedAddressId = '';
      }
    },
    removeSavedAddress(id) {
      this.stage = this.Stages.DELETING_ADDRESS;
      return deleteAddress(id)
        .then(() => {
          this.savedAddresses = this.savedAddresses.filter(a => a.id !== id);
          this.resetSelectedAddress(this.savedAddresses);
          this.stage = this.Stages.DELETING_SUCCESSFUL;
          this.$emit('removed_address');
        })
        .catch(() => {
          this.stage = this.Stages.DELETING_FAILED;
        });
    },

    discoverPeers() {
      this.$parent.$emit('started_peer_discovery');
      this.discoveryStage = this.Stages.PEER_DISCOVERY_STARTED;
      return fetchDynamicAddresses(this.fetchAddressArgs)
        .then(devices => {
          this.discoveredAddresses = devices;
          this.$parent.$emit('finished_peer_discovery');
          setTimeout(() => {
            this.discoveryStage = this.Stages.PEER_DISCOVERY_SUCCESSFUL;
          }, this.discoverySpinnerTime);
          this.discoveredAddressesInitiallyFetched = true;
        })
        .catch(() => {
          this.$parent.$emit('peer_discovery_failed');
          this.discoveryStage = this.Stages.PEER_DISCOVERY_FAILED;
        });
    },

    startDiscoveryPolling() {
      this.discoverPeers();
      if (!this.intervalId) {
        this.intervalId = setInterval(this.discoverPeers, 5000);
      }
    },

    stopDiscoveryPolling() {
      if (this.intervalId) {
        this.intervalId = clearInterval(this.intervalId);
      }
    },

    handleSubmit() {
      if (this.selectedAddressId) {
        const match = find(this.addresses, { id: this.selectedAddressId });
        match.isDynamic = Boolean(find(this.discoveredAddresses, { id: this.selectedAddressId }));
        this.$emit('submit', match);
      }
    },
  },
  $trs: {
    deletingFailedText: 'There was a problem removing this address',
    fetchingFailedText: 'There was a problem getting the available addresses',
    forgetAddressButtonLabel: 'Forget',
    header: 'Select network address',
    newAddressButtonLabel: 'Add new address',
    noAddressText: 'There are no addresses yet',
    refreshAddressesButtonLabel: 'Refresh addresses',
  },
};

