//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'CoreMenuOption',
  props: {
    isDivider: Boolean,
    label: String,
    link: String,
    secondaryText: String,
    icon: String,
  },
  inject: ['showActive'],
  computed: {
    active() {
      let showActive = typeof this.showActive !== 'undefined' ? this.showActive : true;
      return showActive && window.location.pathname.startsWith(this.link);
    },
    optionStyle() {
      if (this.active) {
        return {
          color: this.$themeTokens.primaryDark,
          fontWeight: 'bold',
          backgroundColor: this.$themeBrand.primary.v_50,
          ':hover': {
            backgroundColor: this.$themeBrand.primary.v_100,
          },
        };
      }
      return {
        color: this.$themeTokens.text,
        ':hover': {
          backgroundColor: this.$themeBrand.primary.v_50,
        },
      };
    },
    optionIconStyle() {
      if (this.active) {
        return { fill: this.$themeTokens.primary };
      }
      return { fill: this.$themePalette.grey.v_600 };
    },
  },
  methods: {
    conditionalEmit() {
      if (!this.link) {
        this.$emit('select');
      }
    },
  },
};

