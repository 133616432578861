//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapState } from 'vuex';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import UiToolbar from 'kolibri.coreVue.components.UiToolbar';
import KIconButton from 'kolibri-design-system/lib/buttons-and-links/KIconButton';
import CoreMenu from 'kolibri.coreVue.components.CoreMenu';
import CoreMenuOption from 'kolibri.coreVue.components.CoreMenuOption';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';
import UiButton from 'kolibri-design-system/lib/keen/UiButton';
import navComponents from 'kolibri.utils.navComponents';
import { NavComponentSections } from 'kolibri.coreVue.vuex.constants';
import branding from 'kolibri.utils.branding';
import navComponentsMixin from '../mixins/nav-components';
import LogoutSideNavEntry from './LogoutSideNavEntry';
import SkipNavigationLink from './SkipNavigationLink';

const hashedValuePattern = /^[a-f0-9]{30}$/;

export default {
  name: 'AppBar',
  components: {
    UiToolbar,
    KIconButton,
    CoreMenu,
    UiButton,
    CoreMenuOption,
    LogoutSideNavEntry,
    UserTypeDisplay,
    SkipNavigationLink,
  },
  mixins: [commonCoreStrings, navComponentsMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userMenuDropdownIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isUserLoggedIn', 'getUserKind']),
    ...mapState({
      username: state => state.core.session.username,
      fullName: state => state.core.session.full_name,
    }),
    menuOptions() {
      return navComponents
        .filter(component => component.section === NavComponentSections.ACCOUNT)
        .filter(this.filterByRole);
    },
    // temp hack for the VF plugin
    dropdownName() {
      return !hashedValuePattern.test(this.username) ? this.username : this.fullName;
    },
  },
  created() {
    window.addEventListener('click', this.handleWindowClick);
    this.$kolibriBranding = branding;
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },
  methods: {
    handleWindowClick(event) {
      if (
        !this.$refs.userMenuDropdown.$el.contains(event.target) &&
        !this.$refs.userMenuButton.$el.contains(event.target) &&
        this.userMenuDropdownIsOpen
      ) {
        this.userMenuDropdownIsOpen = false;
      }
      return event;
    },
    handleCoreMenuClose() {
      this.userMenuDropdownIsOpen = false;
      if (this.$refs.userMenuButton.$refs.button) {
        this.$refs.userMenuButton.$refs.button.focus();
      }
    },
    handleChangeLanguage() {
      this.$emit('showLanguageModal');
      this.userMenuDropdownIsOpen = false;
    },
  },
  $trs: {
    openNav: 'Open site navigation',
    languageSwitchMenuOption: 'Change language',
    userMenu: 'User menu',
  },
};

