//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';
import { now } from 'kolibri.utils.serverClock';
import taskStrings from 'kolibri.coreVue.mixins.commonTaskStrings';

export default {
  name: 'FacilityNameAndSyncStatus',
  components: {
    UiIcon,
  },
  mixins: [taskStrings],
  props: {
    facility: {
      type: Object,
      required: true,
    },
    isSyncing: {
      type: Boolean,
      default: false,
    },
    syncHasFailed: {
      type: Boolean,
      default: false,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      now: now(),
    };
  },
  computed: {},
  methods: {
    formattedTime(datetime) {
      if (this.now - new Date(datetime) < 10000) {
        return this.$tr('justNow');
      }
      return this.$formatRelative(datetime, { now: this.now });
    },
  },
  $trs: {
    registeredAlready: 'Registered to `Kolibri Data Portal`',
    neverSynced: {
      message: 'Never synced',
      context:
        '\nThis is associated with the label "Last successful sync:", and the subject is the Facility',
    },
    lastSync: 'Last successful sync:',
    justNow: {
      message: 'Just now',
      context:
        '\nThis is used to indicate when an event occurred. It\'s associated with the label "Last successful sync:"',
    },
    syncFailed: 'Most recent sync failed',
    syncing: 'Syncing',
  },
};

